<template>
  <div id="app">
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="true"></loading>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import { login } from "@/api/http";

export default {
  name: "App",

  components: {
    Loading,
  },

  computed: {
    ...mapGetters({
      isLoading: "loading/isLoading",
      isPendingWinnerEntry: "campaign/isPendingWinnerEntry",
    }),
  },

  methods: {
    ...mapActions({
      setAccessToken: `user/setAccessToken`,
    }),

    ...mapMutations({
      setCampaignInfo: "campaign/SET_CAMPAIGN_INFO",
    }),

    async init() {
      try {
        let QUERY = "";

        switch (process.env.NODE_ENV) {
          case "development":
            QUERY = process.env.VUE_APP_SALEFORCE_QUERY;
            break;

          default:
            QUERY = this.$route.query.QUERY || this.$route.query.query;
            break;
        }
        
        const response = await login(QUERY);
        this.setCampaignInfo(response);
        this.setAccessToken(response.token);

        if (this.isPendingWinnerEntry) {
          return this.$router.push({ name: "Form" }).catch((err) => err);
        }

        this.gotoHomePage();
      } catch (error) {
        console.log(error);
      }
    },

    gotoHomePage() {
      this.$router.push({ name: "Home" }).catch((err) => err);
    },
  },

  async created() {
    this.init();
  },
};
</script>

<style lang="scss">
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/@braid/vue-formulate/themes/snow/snow.scss";
@import "@/styles/app.scss";
</style>
