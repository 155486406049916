import { enter, answer, fillWinner } from "@/api/http";

export default {
  namespaced: true,

  state: {
    campaign: null,
  },

  getters: {
    campaignInfo(state) {
      return state.campaign;
    },

    taskInfo(state) {
      return state.campaign.task;
    },

    campaignData(state) {
      return state.campaign.campaign_data;
    },

    isActiveCampaign(state) {
      return state.campaign.active_campaign === 1;
    },

    chancesLeft(state) {
      return state.campaign.chances_left;
    },

    luckyData(state) {
      return state.campaign.lucky;
    },

    needToPlay(state) {
      const { task_needed, show_lucky_number } = state.campaign;
      return task_needed === 1 && show_lucky_number === 0;
    },

    isEReward(state) {
      const is_e_reward =
        state.campaign.lucky?.is_e_reward || state.campaign?.is_e_reward;
      return is_e_reward === 1;
    },

    isPrizePool(state) {
      return state.campaign.lucky?.reward_type == "prizepool";
    },

    getReward(state) {
      const { reward_name, is_e_reward, e_reward_code } =
        state.campaign.lucky || state.campaign;
      return { reward_name, is_e_reward, e_reward_code };
    },

    isPendingWinnerEntry(state) {
      return +state.campaign.pending_winner_entry === 1;
    },

    isSuccess(state) {
      return +state.campaign.success === 1;
    },
  },

  actions: {
    async enter({ commit }) {
      const { data } = await enter();
      commit("SET_CAMPAIGN_INFO", data);
    },
    async answer({ commit }, payload) {
      const { data } = await answer(payload);
      commit("SET_CAMPAIGN_INFO_FROM_ANSWER", data);
    },
    async fillWinner({ commit }, payload) {
      const { data } = await fillWinner(payload);
      commit("SET_CAMPAIGN_INFO", data);
    },
  },

  mutations: {
    SET_CAMPAIGN_INFO(state, payload) {
      state.campaign = payload;
    },
    SET_CAMPAIGN_INFO_FROM_ANSWER(state, payload) {
      const campaign = state.campaign;
      state.campaign = Object.assign({}, campaign, payload);
    },
  },
};
