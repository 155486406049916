import * as lottie from "@/libs/lottie";

import * as winner from "./winner.json";
import * as point from "./point.json";
import * as fail from "./fail.json";

const animations = [winner, point, fail];

export function loadAnimation(lucky) {
  var animationDataIndex = 2;

  if (lucky) {
    const isWinner = lucky.is_winner == 1;
    const hasConsolidationPrize = +lucky.consolidation_prize === 1;

    animationDataIndex =
      isWinner && !hasConsolidationPrize ? 0 : hasConsolidationPrize ? 1 : 2;
  }

  const animationData = animations[animationDataIndex];

  if (animationDataIndex == 0) {
    const pictureArray = lucky.picture.split("/");

    const file = pictureArray[pictureArray.length - 1];
    const url = lucky.picture.replace(file, "");

    animationData.default.assets[1].u = url;
    animationData.default.assets[1].p = file;
  } else if (animationDataIndex == 1) {
    for (let index = 0; index < animationData.default.layers.length; index++) {
      const layer = animationData.default.layers[index];

      if (layer.nm == "1234567890, " || layer.nm == "1234567890, 2") {
        layer.t.d.k[0].s.t = `${lucky.consolidation_sf_point}\r`;
        animationData.default.layers[index] = layer;
      }
    }
  } else {
    // null
  }

  const params = {
    loop: false,
    autoplay: false,
    renderer: "svg",
    animationData: animationData.default,
    container: document.getElementById("lottie"),
  };

  return lottie.loadAnimation(params);
}
