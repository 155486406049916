<template>
  <div v-if="ready" id="result">
    <surprise-box ref="anim" @complete="animationComplete = true" />

    <div
      class="content"
      v-if="animationComplete"
      :style="{
        top: '60px',
        position: 'absolute',
        transform: 'translateX(5%)',
      }"
    >
      <p class="title" :class="{ yellow: isWinner || hasConsolidationPrize }">
        {{
          isWinner || hasConsolidationPrize
            ? "ขอแสดงความยินดี"
            : "ร่วมทำกิจกรรมใหม่ในครั้งถัดไป"
        }}
      </p>
      <div class="body">
        <div v-if="needToPlay">
          <p v-if="isCorrectAnswer" class="sub">
            คุณเข้าสู่ระบบลำดับที่
          </p>

          <p v-else class="sub red">
            {{
              this.taskType == "fill"
                ? "คุณเลือกคำตอบผิด"
                : "คำตอบที่ถูกต้องคือ"
            }}
          </p>

          <p v-if="isCorrectAnswer" class="no">{{ luckyData.number }}</p>
          <p v-else class="correct-answer">{{ campaignInfo.real_answer }}</p>
        </div>

        <div v-if="isWinner || hasConsolidationPrize" class="reward-block">
          <p class="text-grey">คุณได้รับรางวัล</p>
          <p class="reward text-blue">
            {{
              luckyData.reward_name ||
                `${luckyData.consolidation_sf_point} คะแนน`
            }}
          </p>
        </div>

        <p v-else>
          ขอบคุณที่ร่วมกิจกรรม<br /><span class="text-blue"
            >มาร่วมทำกิจกรรมกันใหม่ในครั้งถัดไป</span
          >
        </p>

        <button class="btn btn-link mb-3" @click="showModal = true">
          ดูรายชื่อผู้รับรางวัลทั้งหมด
        </button>
      </div>
      <div class="action">
        <button type="button" class="btn btn-primary" @click="next">
          {{
            isWinner
              ? "รับรางวัล"
              : hasConsolidationPrize
              ? "กลับหน้าหลัก"
              : "ตกลง"
          }}
        </button>
      </div>
    </div>

    <UserListModal v-if="showModal" @close="closeModal" :winners="winners" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { winnerList } from "@/api/http";
import SurpriseBox from "@/components/SurpriseBox.vue";
import UserListModal from "@/components/UserListModal.vue";

export default {
  name: "Result",

  components: {
    SurpriseBox,
    UserListModal,
  },

  data: () => ({
    winners: [],
    ready: false,
    showModal: false,
    animationComplete: false,
  }),

  watch: {
    animationComplete(n, o) {
      if (n == true && o == false) {
        document.querySelector("#result").style.overflowY = `scroll`;
      }
    },
  },

  computed: {
    ...mapGetters({
      taskInfo: "campaign/taskInfo",
      luckyData: "campaign/luckyData",
      needToPlay: "campaign/needToPlay",
      campaignData: "campaign/campaignData",
      campaignInfo: "campaign/campaignInfo",
    }),

    taskType() {
      return this.taskInfo?.correct_answer || "";
    },

    isWinner() {
      const isWinner = +this.luckyData?.is_winner === 1;
      return isWinner || false;
    },

    hasConsolidationPrize() {
      return +this.luckyData?.consolidation_prize === 1;
    },

    isCorrectAnswer() {
      return +this.campaignInfo.correct_answer === 1;
    },

    contentTopPosition() {
      const top = document
        .querySelector("#lottie svg g g.png")
        .getBoundingClientRect().top;

      const height = document.querySelector("#lottie").getBoundingClientRect()
        .height;

      const diff = height - window.innerHeight;

      return -(window.innerHeight - top + diff);
    },
  },

  async mounted() {
    await this.fetchWinners();
    await this.wait(50);
    this.ready = true;

    this.$nextTick(async () => {
      await this.wait(100);
      this.$refs.anim.play();
    });
  },

  methods: {
    redirect() {
      if (this.campaignData?.redirect_url)
        window.parent.location = this.campaignData.redirect_url;
    },

    next() {
      if (!this.isWinner) return this.redirect();

      if (this.luckyData.reward_type == "prizepool") {
        this.$router.push({ name: "Reward" });
        return;
      }

      this.$router.push({ name: "Form" });
    },

    async fetchWinners() {
      const { data } = await winnerList();
      this.winners = data.winner_list;
    },

    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/result.scss";
</style>
