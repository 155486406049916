<template>
  <div id="home">
    <div class="content">
      <p v-if="noMoreTiresLeft" class="counter-wording">
        คุณเข้าร่วมกิจกรรมแล้ว<br />
        ลำดับของคุณคือ {{ campaignInfo.last_played_number }}
      </p>
      <p v-else class="counter-wording">
        รอบนี้คุณเหลือสิทธิ์อีก {{ chancesLeft }} ครั้ง
      </p>
      <div class="action">
        <button
          v-if="isActiveCampaign"
          type="button"
          class="btn btn-light"
          :class="{ disabled: noMoreTiresLeft }"
          @click="start"
        >
          {{ noMoreTiresLeft ? "กลับสู่หน้าหลัก" : "กดเพื่อเปิดกล่อง" }}
        </button>
      </div>
    </div>
    <div class="header">
      <div class="logo animated bounceIn delay-1s">
        <img src="@/assets/new-design/logo.png" />
      </div>
    </div>
    <div v-if="hasMedia" class="banner">
      <img v-if="hasImage" :src="campaignData.picture_url" />
      <div v-else-if="hasVideo" class="video-container">
        <iframe
          class="responsive-iframe"
          mozallowfullscreen="1"
          webkitallowfullscreen="1"
          allowfullscreen="1"
          allow="autoplay; fullscreen"
          :src="iFrameVieoURL"
        ></iframe>
      </div>
    </div>
    <!-- <div class="content" :class="{ 'no-image-video': !hasMedia }"> -->
    <p class="summary-text" v-if="campaignData.summary">
      {{ campaignData.summary || "" }}
    </p>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CampaignMixin from "@/mixins/campaign.mixins.js";

export default {
  name: "Home",

  mixins: [CampaignMixin],

  computed: {
    ...mapGetters({
      chancesLeft: "campaign/chancesLeft",
      campaignInfo: "campaign/campaignInfo",
      campaignData: "campaign/campaignData",
      isActiveCampaign: "campaign/isActiveCampaign",
    }),

    noMoreTiresLeft() {
      return this.chancesLeft <= 0;
    },
  },

  methods: {
    redirect() {
      if (this.campaignData?.redirect_url)
        window.parent.location = this.campaignData.redirect_url;
    },

    start() {
      this.noMoreTiresLeft
        ? this.redirect()
        : this.$router.push({ name: "Quiz" });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/home.scss";
</style>
