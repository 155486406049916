import { mapGetters } from "vuex";

export default {
  data() {
    return {
      submitEnable: false,
    };
  },

  computed: {
    ...mapGetters({
      taskInfo: "campaign/taskInfo",
    }),

    instruction() {
      return this.taskInfo && this.taskInfo.instruction
        ? this.taskInfo.instruction
        : "";
    },

    description() {
      return this.taskInfo && this.taskInfo.description
        ? this.taskInfo.description
        : "";
    },

    taskType() {
      return this.taskInfo?.correct_answer || "";
    },

    hasMedia() {
      return this.taskInfo && this.taskInfo.image_or_video != "none";
    },

    hasImage() {
      return this.taskInfo && this.taskInfo.image_or_video == "image";
    },

    hasVideo() {
      return this.taskInfo && this.taskInfo.image_or_video == "video";
    },

    submitDelay() {
      return this.taskInfo ? this.taskInfo.submit_button_delay : 0;
    },

    iFrameVieoURL() {
      const { search, origin, pathname } = new URL(this.taskInfo.video_url);
      const parsed = parse(search.substring(1));
      parsed.autoPlay = 1;

      const queryString = stringify(parsed, {
        addQueryPrefix: true,
      });

      return `${origin}${pathname}${queryString}`;
    },
  },

  async mounted() {
    // wait to set state
    await this.wait(500);

    // delay active submit button
    await this.wait(this.submitDelay);
    this.submitEnable = true;
  },
};
