import { parse, stringify } from "qs";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      campaignData: "campaign/campaignData",
    }),

    hasMedia() {
      return this.campaignData && this.campaignData.picture_or_video != "none";
    },

    hasImage() {
      return this.campaignData && this.campaignData.picture_or_video == "image";
    },

    hasVideo() {
      return this.campaignData && this.campaignData.picture_or_video == "video";
    },

    iFrameVieoURL() {
      const { search, origin, pathname } = new URL(this.campaignData.video_url);
      const parsed = parse(search.substring(1));
      parsed.autoPlay = 1;

      const queryString = stringify(parsed, {
        addQueryPrefix: true,
      });

      return `${origin}${pathname}${queryString}`;
    },
  },
};
