import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueFormulate from "@braid/vue-formulate";
import VueCountdown from "@chenfengyuan/vue-countdown";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import "./mixins";

Vue.use(Loading);
Vue.use(VueFormulate);
Vue.component(VueCountdown.name, VueCountdown);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
