<template>
  <div class="modal-mask">
    <transition
      name="modal"
      enter-active-class="animated bounceIn"
      leave-active-class="animated bounceOut"
    >
      <div v-if="ready" class="modal-wrapper">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">ดูรายชื่อผู้รับรางวัล</h4>
              <button type="button" class="close" @click="close">
                <span aria-hidden="true">&times;</span> ปิด
              </button>
            </div>
            <div class="modal-body">
              <table>
                <tr v-for="(winner, n) in winners" :key="n">
                  <td>
                    <p>
                      ลำดับ {{ winner.lucky_number }} |
                      {{ winner.winner_timestamp }}
                    </p>
                    <span class="name"
                      >{{ winner.firstname }} {{ winner.lastname }}</span
                    >
                  </td>
                  <td>
                    {{ winner.reward_name }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["winners"],

  data() {
    return {
      ready: false,
    };
  },

  mounted() {
    setTimeout(() => {
      this.ready = true;
    }, 50);
  },

  methods: {
    close() {
      this.ready = false;
      setTimeout(() => {
        this.$emit("close");
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  border-radius: 12px;

  .modal-header {
    background-color: #195a9e;
    border-radius: 12px 12px 0px 0px;

    .modal-title {
      width: 100%;
      color: #fff;
      display: block;
      line-height: 1;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
    }

    .close {
      top: -2rem;
      opacity: 1;
      right: 0px;
      color: #fff;
      font-size: 32px;
      font-weight: 400;
      text-align: right;
      padding: 4px 1rem;
      text-shadow: none;
      position: absolute;

      span {
        font-size: 42px;
      }
    }
  }
}

.modal-body {
  padding: 0px;
  overflow: scroll;
  max-height: 400px;
  min-height: 400px;

  table {
    tr {
      border-bottom: 1px solid #999;

      td {
        line-height: 1;
        font-size: 22px;
        padding: 0.75rem 1rem;

        > p {
          margin-bottom: 0.5rem;
        }

        .name {
          font-size: 24px;
          font-weight: 600;
        }
      }

      td:nth-child(1) {
        border-right: 1px solid #999;
      }

      td:nth-child(2) {
        width: 30%;
        padding: 0.5rem;
        color: #195a9e;
        text-align: center;
      }
    }
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  // transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

/* This only changes this particular animation duration */
.animate__animated.animate__bounce {
  --animate-duration: 1s;
}
</style>
