import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Quiz from "@/views/Quiz.vue";
import Form from "@/views/Form.vue";
import Result from "@/views/Result.vue";
import Article from "@/views/Article.vue";
import Reward from "@/views/Reward.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/quiz",
    name: "Quiz",
    component: Quiz,
  },
  {
    path: "/article",
    name: "Article",
    component: Article,
  },
  {
    path: "/result",
    name: "Result",
    component: Result,
    beforeEnter(to, from, next) {
      if (!["Quiz", "Article"].includes(from.name)) {
        window.location.href = "/";
        return;
      }

      next();
    },
  },
  {
    path: "/form",
    name: "Form",
    component: Form,
    beforeEnter(to, from, next) {
      const { require_winner_address } = store.getters["campaign/campaignData"];

      if (+require_winner_address === 0) {
        return next("/reward");
      }

      next();
    },
  },
  {
    path: "/reward",
    name: "Reward",
    component: Reward,
    beforeEnter(to, from, next) {
      if (!["Form", "Result"].includes(from.name)) {
        window.location.href = "/";
        return;
      }

      next();
    },
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("loading/setLoading", false);

  next();
});

export default router;
