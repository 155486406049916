<template>
  <div id="reward">
    <div class="bg-layer">
      <!-- <img src="@/assets/images/Element-1.png" /> -->
    </div>
    <div class="content vertical-center">
      <p class="title">
        ยินดีด้วยค่ะ
      </p>
      <form class="body">
        <div class="center">
          <img class="logo" src="@/assets/new-design/logo.png" />
        </div>

        <div class="center">
          <p class="text">
            คุณคือร้านค้าที่ได้รับรางวัล<br />{{ reward.reward_name }}
          </p>

          <hr />

          <div v-if="isPrizePool" class="wallet-link-wrapper">
            <p class="text bold">กรุณาเคลมของรางวัลที่<br />กระเป๋ารางวัล</p>

            <button type="button" class="btn btn-primary" @click="openWallet">
              กระเป๋ารางวัล
            </button>
          </div>

          <div v-else-if="isEReward">
            <p class="code">{{ reward.e_reward_code }}</p>
            <p class="text bold">กรุณาบันทึกหน้าจอนี้<br />ก่อนออก</p>
          </div>

          <div v-else>
            <p class="text py-3">
              กรุณารอเจ้าหน้าที่ดำเนินการ<br />
              จัดส่งของรางวัล
            </p>

            <p class="text sub mb-0">
              หากมีข้อสงสัย โปรดติดต่อ<br />น้องจริงใจที่ไลน์ VIP by PMTT
            </p>
          </div>
        </div>
      </form>
      <div class="action">
        <button type="button" class="btn btn-link" @click="redirect">
          กลับหน้าหลัก
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const PRIZE_WALLET_URL = process.env.VUE_APP_PRIZE_WALLET;

export default {
  name: "Reward",

  computed: {
    ...mapGetters({
      reward: "campaign/getReward",
      isEReward: "campaign/isEReward",
      isPrizePool: "campaign/isPrizePool",
      campaignData: "campaign/campaignData",
    }),
  },

  methods: {
    redirect() {
      if (this.campaignData?.redirect_url)
        window.parent.location = this.campaignData.redirect_url;
      else this.$router.push({ name: "Home" });
    },

    openWallet() {
      window.parent.location = PRIZE_WALLET_URL;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/reward.scss";
</style>
