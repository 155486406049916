<template>
  <div id="article">
    <div class="header">{{ instruction }}</div>

    <div class="image-video">
      <img v-if="hasImage" :src="taskInfo.image_url" />

      <div v-else-if="hasVideo" class="video-container">
        <iframe
          class="responsive-iframe"
          mozallowfullscreen="1"
          webkitallowfullscreen="1"
          allowfullscreen="1"
          allow="autoplay; fullscreen"
          :src="iFrameVieoURL"
        ></iframe>
      </div>
    </div>

    <div class="content">
      <p class="words">{{ description }}</p>

      <div class="action">
        <p class="delay-text">
          <countdown :time="submitDelay" v-slot="{ seconds }">
            {{
              !submitEnable && submitDelay > 0
                ? `สามารถกดได้ในอีก ${seconds} วินาที.`
                : ""
            }}
          </countdown>
        </p>

        <button
          type="button"
          class="btn btn-light"
          :disabled="!submitEnable"
          @click="submit"
        >
          ยืนยัน
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TaskMixin from "@/mixins/task.mixins.js";

export default {
  name: "Article",

  mixins: [TaskMixin],

  computed: {
    ...mapGetters({
      taskInfo: "campaign/taskInfo",
      needToPlay: "campaign/needToPlay",
    }),
  },

  methods: {
    ...mapActions({
      answer: "campaign/answer",
    }),

    async submit() {
      await this.answer();
      this.$router.push({ name: "Result" });
    },
  },

  async created() {
    if (!this.needToPlay) return this.$router.push({ name: "Result" });
    if (this.taskType != "none") return this.$router.push({ name: "Quiz" });
  },
};
</script>
<style lang="scss">
@import "@/styles/article.scss";
</style>
