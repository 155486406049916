<template>
  <div id="form">
    <div class="bg-layer">
      <img src="@/assets/images/Element-1.png" />
    </div>
    <div class="content">
      <p class="title">
        กรอกข้อมูลเพื่อรับของรางวัล
      </p>
      <form class="body">
        <div class="center">
          <img class="logo" src="@/assets/new-design/logo.png" />
        </div>

        <div class="center">
          <p class="text">
            คุณคือร้านค้าที่ได้รับรางวัล<br />
            {{ rewardName }}
          </p>
          <hr />
          <p class="text">
            กรุณากรอกข้อมูลเพื่อยืนยันสิทธิ์/<br />การจัดส่งของรางวัล
            และเอกสารดังต่อไปนี้
          </p>
        </div>

        <FormInput
          type="text"
          label="ชื่อ"
          ref="firstname"
          name="firstname"
          placeholder="ชื่อ"
          classes="form-control"
          :error="errors.firstname"
          v-model="values.firstname"
          @validate="validate('firstname')"
        />

        <FormInput
          type="text"
          label="นามสกุล"
          ref="lastname"
          name="lastname"
          placeholder="นามสกุล"
          classes="form-control"
          :error="errors.lastname"
          v-model="values.lastname"
          @validate="validate('lastname')"
        />

        <FormInput
          type="text"
          ref="house_no"
          name="house_no"
          label="ที่อยู่สำหรับการรับของรางวัล"
          :error="errors.house_no"
          v-model="values.house_no"
          classes="form-control"
          placeholder="บ้านเลขที่/อาคาร*"
        />

        <FormInput
          type="text"
          name="road"
          :error="errors.road"
          v-model="values.road"
          classes="form-control"
          placeholder="ตรอก/ซอย/ถนน*"
        />

        <div class="form-group">
          <FormulateInput
            v-model="province_selected"
            :options="getProvinceList()"
            type="select"
            placeholder="จังหวัด*"
          />
        </div>

        <div class="form-group">
          <FormulateInput
            v-model="amphoe_selected"
            :options="getAmphoeList(province_selected)"
            type="select"
            placeholder="อำเภอ/เขต*"
          />
        </div>

        <div class="form-group">
          <FormulateInput
            v-model="district_selected"
            :options="getDistrictList(amphoe_selected)"
            type="select"
            placeholder="ตำบล/แขวง*"
          />
        </div>

        <div class="form-group">
          <FormulateInput
            class="mb-0"
            type="select"
            v-model="zipcode_selected"
            :options="getZipCode(amphoe_selected)"
            placeholder="รหัสไปรษณีย์*"
          />
          <p class="form-input-hint px-2" v-if="!!errors.address">
            กรุณากรอกที่อยู่สำหรับการรับของรางวัลให้ครบถ้วน
          </p>
        </div>

        <div class="form-group">
          <label>เบอร์โทรศัพท์<span class="red">*</span></label>
          <the-mask
            type="text"
            ref="telephone"
            class="form-control"
            :mask="['0##-###-####']"
            placeholder="เบอร์โทรศัพท์*"
            v-model="values.telephone"
            @blur.native="validate('telephone')"
          />
          <p class="form-input-hint" v-if="!!errors.telephone">
            {{ errors.telephone }}
          </p>
        </div>

        <div class="form-group">
          <label>เลขที่บัตรประชาชน<span class="red">*</span></label>
          <the-mask
            type="text"
            ref="id_card_no"
            class="form-control"
            :mask="['#-####-#####-##-#']"
            placeholder="เลขที่บัตรประชาชน 13 หลัก"
            v-model="values.id_card_no"
            @blur.native="validate('id_card_no')"
            @keyup.native="validate('id_card_no')"
          />
          <p class="form-input-hint" v-if="!!errors.id_card_no">
            {{ errors.id_card_no }}
          </p>
        </div>

        <div class="form-group">
          <div class="pretty p-icon p-curve">
            <input type="checkbox" v-model="values.license_confirm" />
            <div class="state">
              <i class="icon mdi mdi-check"></i>
              <label>
                ร้านค้าให้การยืนยันว่า เป็นร้านค้าที่ได้รับใบอนุญาตให้ขายยาสูบ
                ที่ถูกต้องตามกฎหมายและยังไม่หมดอายุ
              </label>
            </div>
          </div>
        </div>

        <p class="text">
          หากของรางวัลมีมูลค่าตั้งแต่ 1,000 บาทขึ้นไป
          ทางบริษัทจะเป็นผู้ออกและนำส่งภาษีเงินได้ หัก ณ ที่จ่าย
          หากท่านต้องการรับหนังสือรับรองหัก ณ ที่จ่าย
          โปรดเลือกช่องทางการรับเอกสาร หรือกรุณาติดต่อ น้องจริงใจ
        </p>

        <div class="form-group">
          <div class="pretty p-default p-round">
            <input
              type="radio"
              name="tax_document"
              value="line"
              v-model="values.tax_document"
            />
            <div class="state">
              <label>ทางไลน์ VIP by PMTT</label>
            </div>
          </div>

          <div class="pretty p-default p-round">
            <input
              type="radio"
              name="tax_document"
              value="vip"
              v-model="values.tax_document"
            />
            <div class="state">
              <label>อัพโหลดเข้าแอปฯ VIP</label>
            </div>
          </div>

          <div class="pretty p-default p-round">
            <input
              type="radio"
              name="tax_document"
              value="email"
              v-model="values.tax_document"
            />
            <div class="state">
              <label>ทาง Email โปรดระบุ</label>
            </div>
          </div>

          <div class="form-group" data-attr="email">
            <input
              v-model="values.email"
              ref="email"
              type="email"
              class="form-control"
              placeholder="Email"
              :disabled="values.tax_document !== 'email'"
            />
            <p class="form-input-hint" v-if="!!errors.email">
              กรุณาระบุอีเมลให้ถูกต้อง
            </p>
          </div>

          <div class="pretty p-default p-round">
            <input
              type="radio"
              name="tax_document"
              value="none"
              v-model="values.tax_document"
            />
            <div class="state">
              <label>ไม่ต้องการรับหนังสือรับรองหัก ณ ที่จ่าย</label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="pretty p-icon p-curve">
            <input type="checkbox" v-model="values.age_confirm" />
            <div class="state">
              <i class="icon mdi mdi-check"></i>
              <label>
                ข้าพเจ้า ซึ่งเป็นผู้มีอำนาจกระทำการแทนร้านค้า
                เป็นผู้มีอายุตั้งแต่ 18 ปีขึ้นไป ข้าพเจ้าได้รับทราบ
                และยอบรับข้อกำหนดและเงื่อนไขดังกล่าวข้างต้น
              </label>
            </div>
          </div>
        </div>

        <div class="center">
          <p class="text">
            บริษัทขอสงวนสิทธิ์ในการยกเลิกของรางวัล<br />
            สำหรับผู้ที่กรอกข้อมูลเข้ามาไม่ครบถ้วน
          </p>
        </div>
      </form>
      <div class="action">
        <button
          type="button"
          @click="submit"
          class="btn btn-primary"
          :disabled="!readyToSubmit"
        >
          ยืนยัน
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";

import { preFillWinner } from "@/api/http";

import FormInput from "@/components/FormInput";
import commonsMixin from "@/mixins/commons.mixins";
import provinceMixin from "@/mixins/province.mixins";

import { formObject, formSchema } from "@/form.js";

export default {
  name: "Form",

  components: { TheMask, FormInput },

  mixins: [commonsMixin, provinceMixin],

  data() {
    return { ...formObject, prefill: null };
  },

  watch: {
    "values.email"(n, o) {
      this.errors.email = !this.validateEmail();
    },

    "values.tax_document"(n, o) {
      if (n != "email") {
        this.errors.email = false;
      }
    },
  },

  computed: {
    ...mapGetters({
      reward: "campaign/getReward",
      isSuccess: "campaign/isSuccess",
      luckyData: "campaign/luckyData",
      campaignData: "campaign/campaignData",
    }),

    rewardName() {
      return this.reward?.reward_name || this.luckyData?.reward_name || "";
    },

    readyToSubmit() {
      const { license_confirm, age_confirm } = this.values;
      return license_confirm && age_confirm;
    },
  },

  methods: {
    ...mapActions({
      fillWinner: "campaign/fillWinner",
      loadPrefill: "campaign/loadPrefill",
    }),

    getFormData() {
      const form = this.values;

      delete form.zipcode;
      delete form.district;

      form.age_confirm = form.age_confirm === true ? 1 : 0;
      form.license_confirm = form.license_confirm === true ? 1 : 0;

      return form;
    },

    validate(field) {
      formSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },

    validateAddress() {
      this.errors.address = false;

      const {
        house_no,
        road,
        tambon,
        amphoe,
        province,
        zip_code,
      } = this.values;

      return !(
        !house_no ||
        !road ||
        !tambon ||
        !amphoe ||
        !province ||
        !zip_code
      );
    },

    validateEmail() {
      const { email, tax_document } = this.values;
      const validEmail =
        tax_document != "email" ||
        (tax_document == "email" && this.isValidEmail(email));

      return validEmail;
    },

    normalizeAddress() {
      const { province, amphoe, district, zipcode } = this.getAddress();
      this.values.province = province;
      this.values.amphoe = amphoe;
      this.values.tambon = district;
      this.values.zip_code = zipcode;
    },

    isFormValid() {
      if (!this.values.firstname) {
        this.$refs.firstname.$el.querySelector("[for='firstname']").focus();
        return false;
      }

      if (!this.values.lastname) {
        this.$refs.lastname.$el.querySelector("[for='lastname']").focus();
        return false;
      }

      const addressValid = this.validateAddress();
      this.errors.address = !addressValid;
      if (!addressValid) {
        this.$refs.house_no.$el.querySelector("[for='house_no']").focus();
        return false;
      }

      const emailValid = this.validateEmail();
      this.errors.email = !emailValid;
      if (!emailValid) {
        this.$refs.email.$el.focus();
        return false;
      }

      return true;
    },

    async submit() {
      try {
        this.showLoading();
        this.normalizeAddress();

        if (!this.isFormValid()) {
          this.hideLoading();
          return;
        }

        await formSchema.validate(this.values, { abortEarly: false });

        this.errors = {};

        const form = this.getFormData();

        await this.fillWinner(form);

        this.hideLoading();
        if (!this.isSuccess) return;

        this.$router.push({ name: "Reward" });
      } catch (error) {
        this.hideLoading();

        error?.inner?.forEach((error, index) => {
          this.errors[error.path] = error.message;

          index == 0 &&
            this.$refs[error.path].$el.querySelector("input").focus();
        });

        this.isFormValid();
      }
    },

    async fillForm() {
      const { province, amphoe, tambon, zip_code } = this.prefill;

      this.values.firstname = this.prefill.firstname;
      this.values.lastname = this.prefill.lastname;
      this.values.house_no = this.prefill.house_no;
      this.values.road = this.prefill.road;
      this.values.telephone = this.prefill.telephone;
      this.values.id_card_no = this.prefill.id_card_no;
      this.values.tax_document = this.prefill.tax_document;
      this.values.email = this.prefill.email;

      this.province_selected = this.getAddressByName(
        "province",
        province
      ).province_code;

      await this.wait(100);

      this.amphoe_selected = this.getAddressByName(
        "amphoe",
        amphoe
      ).amphoe_code;

      await this.wait(100);

      this.district_selected = this.getAddressByName(
        "district",
        tambon
      ).district_code;

      await this.wait(100);

      this.zipcode_selected = this.getAddressByName(
        "zipcode",
        zip_code
      ).zipcode;
    },

    async fetchPrefill() {
      const { data } = await preFillWinner();

      if (data && data.pre_fill) {
        this.prefill = data.pre_fill;
        this.fillForm();
      }
    },
  },

  async created() {
    await this.fetchPrefill();
  },
};
</script>

<style lang="scss">
@import "~pretty-checkbox/src/pretty-checkbox.scss";
@import "@/styles/form.scss";
</style>
