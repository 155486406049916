<template>
  <div v-if="ready" id="quiz">
    <div class="header">{{ instruction }}</div>
    <div class="image-video">
      <img v-if="hasImage" :src="taskInfo.image_url" />

      <div v-else-if="hasVideo" class="video-container">
        <iframe class="responsive-iframe" :src="taskInfo.video_url"></iframe>
      </div>
    </div>
    <div
      class="content"
      :class="{
        'is-video': hasVideo,
        'is-image': hasImage,
        'no-image-video': !hasMedia,
      }"
    >
      <h4 class="question">{{ description }}</h4>

      <p class="choice-error" v-if="choices.length > 0 && fillError">
        กรุณาใส่คำตอบ
      </p>
      <p class="choice-error" v-if="choiceError">{{ choiceError }}</p>

      <div class="choice-list">
        <div
          v-for="choice in choices"
          :key="choice.choice_number"
          class="choice"
          :class="getChoiceActiveClass(choice)"
        >
          <label class="radio radio-gradient">
            <span class="radio__input">
              <input
                type="radio"
                name="radio"
                v-model="selected"
                :value="choice.choice_number"
              />
              <span class="radio__control"></span>
            </span>

            <span class="radio__label">
              {{ choice.answer }}
            </span>
          </label>
        </div>

        <div
          v-if="choices.length > 0 && taskType == 'fill'"
          class="choice"
          :class="{
            selected: selected === dynamicId,
          }"
        >
          <label class="radio radio-gradient">
            <span class="radio__input">
              <input
                type="radio"
                name="radio"
                v-model="selected"
                :value="dynamicId"
              />
              <span class="radio__control"></span>
            </span>

            <span class="radio__label">
              <input
                type="text"
                v-model="input_fill"
                class="form-control"
                @focus="selected = dynamicId"
                placeholder="ระบุคำตอบ..."
              />
            </span>
          </label>
        </div>
      </div>

      <div v-if="choices.length <= 0 && taskType == 'fill'" class="answer-box">
        <textarea
          cols="30"
          rows="5"
          maxlength="255"
          class="form-control"
          v-model="fill"
          :placeholder="fillError ? 'กรุณาใส่คำตอบ' : ''"
          :class="{ error: fillError }"
          @keypress="fillError = false"
        ></textarea>
      </div>

      <div class="action">
        <p class="delay-text">
          <countdown :time="submitDelay" v-slot="{ seconds }">
            {{
              !submitEnable && submitDelay > 0
                ? `สามารถกดได้ในอีก ${seconds} วินาที.`
                : ""
            }}
          </countdown>
        </p>

        <button
          type="button"
          class="btn btn-light"
          :disabled="!submitEnable"
          @click="submit"
        >
          ยืนยัน
        </button>
      </div>
    </div>
  </div>
  <div v-else id="quiz-on-loading">Loading...</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TaskMixin from "@/mixins/task.mixins.js";

export default {
  name: "Quiz",

  mixins: [TaskMixin],

  data() {
    return {
      ready: false,
      choiceError: "",
      fillError: false,
      choiceStatus: "default",

      fill: "",
      input_fill: "",
      selected: null,

      dynamicId: `el${new Date().getTime()}`,
    };
  },

  watch: {
    selected(n, o) {
      if (this.taskType == "choice") {
        this.choiceError = "";
      } else if (this.taskType == "fill") {
        if (
          this.choices.length > 0 &&
          this.selected != "" &&
          this.selected != this.dynamicId
        ) {
          this.choiceError = "";
          this.fillError = false;
        } else if (
          this.choices.length > 0 &&
          this.input_fill != "" &&
          this.selected == this.dynamicId
        ) {
          this.choiceError = "";
          this.fillError = false;
        }
      }
    },

    input_fill(n, o) {
      if (
        this.choices.length > 0 &&
        this.input_fill != "" &&
        this.selected == this.dynamicId
      ) {
        this.choiceError = "";
        this.fillError = false;
      }
    },
  },

  computed: {
    ...mapGetters({
      taskInfo: "campaign/taskInfo",
      needToPlay: "campaign/needToPlay",
    }),

    choices() {
      return this.taskInfo && this.taskInfo.choices
        ? this.taskInfo.choices
        : [];
    },
  },

  methods: {
    ...mapActions({
      enter: `campaign/enter`,
      answer: "campaign/answer",
    }),

    validate() {
      if (this.taskType == "choice" && !this.selected) {
        throw new Error("choice_error");
      }

      if (this.choices.length > 0 && this.taskType == "fill") {
        this.fill =
          this.selected == this.dynamicId ? this.input_fill : this.selected;

        if (!this.fill && !this.selected) {
          throw new Error("choice_error");
        }

        if (!this.fill && this.selected == this.dynamicId) {
          throw new Error("fill_error");
        }
        return;
      }

      if (this.choices.length == 0 && this.taskType == "fill") {
        if (!this.fill) throw new Error("fill_error");
        return;
      }
    },

    async submit() {
      try {
        this.validate();

        switch (this.taskType) {
          // choice only
          case "choice":
            await this.answer({ choice: +this.selected });
            break;

          case "fill":
            // // fill only [TEXT AREA]
            if (this.choices.length <= 0) {
              await this.answer({ fill: this.fill });
            }

            // // choice and fill [Radio and input text]
            else if (this.choices.length > 0) {
              // [Radio input]
              if (+this.selected <= this.choices.length) {
                await this.answer({ choice: +this.selected });
              }

              // [Text input]
              else {
                await this.answer({ fill: this.fill });
              }
            }
            break;
        }

        this.$router.push({ name: "Result" });
      } catch (error) {
        this.handleError(error.message);
      }
    },

    hideError() {
      this.choiceError = "";
      this.fillError = false;
    },

    handleError(error) {
      this.hideError();

      switch (error) {
        case "choice_error":
          this.choiceError = "กรุณาเลือกคำตอบ";
          break;
        case "fill_error":
          this.fillError = true;
          break;
      }
    },

    getChoiceActiveClass(choice) {
      if (this.taskType == "fill" && this.selected == choice.answer)
        return "selected";

      if (this.taskType == "choice" && +this.selected === +choice.choice_number)
        return "selected";
    },

    setActiveChoiceClass(event) {
      document
        .querySelector(".choice-list .choice.active")
        .classList.remove("active");

      event.target.classList.add("active");
    },
  },

  async created() {
    await this.enter();

    if (!this.needToPlay) {
      return this.$router.push({ name: "Result" });
    }

    if (this.taskType == "none") {
      return this.$router.push({ name: "Article" });
    }

    setTimeout(() => {
      this.ready = true;
    }, 100);
  },
};
</script>

<style lang="scss">
@import "@/styles/quiz.scss";
</style>
