<template>
  <div id="surprise-box">
    <div refs="lottie" id="lottie" :class="{ blur: blurAnim }"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { loadAnimation } from "@/assets/anims";

export default {
  data() {
    return {
      blurAnim: false,
      animation: null,
    };
  },

  computed: {
    ...mapGetters({
      luckyData: "campaign/luckyData",
    }),
  },

  methods: {
    init() {
      this.animation = loadAnimation(this.luckyData);
      this.animation.onComplete = this.complete;
    },
    play() {
      this.animation.play();
    },
    pause() {
      this.animation.pause();
    },
    stop() {
      this.animation.stop();
    },
    complete() {
      this.blurAnim = true;
      this.$emit("complete");
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.init();

      this.play();
    });
  },
};
</script>

<style lang="scss" scoped>
#surprise-box {
  .reward {
    z-index: 1;

    top: 230px;
    left: 0px;
    width: 100%;
    height: auto;
    position: absolute;

    display: flex;
    justify-content: center;

    img {
      width: 230px;
      height: 230px;
      padding: 1em;
      background: #ccc;
      border-radius: 50%;
    }
  }
}
</style>

<style lang="scss">
#lottie {
  &.blur {
    svg > g > g:nth-child(1),
    svg > g > g:nth-child(2),
    svg > g > g:nth-child(7),
    svg > g > g:nth-child(10) {
      opacity: 0.75;
      filter: blur(2px);
    }
  }
}
</style>
